<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Reporte de personal" :exportInfo="exportItems">
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
        <Basic-Btn text="Filtros" color="primary" icon="mdi-filter" @click="dialogs.filter = true" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.nombre`]="{ item }">
          {{ item.nombre + " " + item.apellido_paterno + " " + item.apellido_materno }}
        </template>
        <template v-slot:[`item.jefe_nombre`]="{ item }">
          {{ item.jefe_nombre + " " + item.jefe_apellido_paterno + " " + item.jefe_apellido_materno }}
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <v-dialog v-model="dialogs.filter" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Filtros</v-card-title>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="12">
              <v-select v-model="forms.filter.mes_nacimiento" :items="meses" item-text="text" item-value="value" label="Mes de nacimiento" prepend-icon="mdi-calendar" dense></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small :disabled="loading" color="disabled" elevation="0" style="margin: 5px" @click="cancel()">
            Cancelar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn small :loading="loading" color="success" elevation="0" style="margin: 5px" @click="applyFilters()">
            Aplicar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { TableContainer, BasicBtn } from "@/components";
import moment from "moment";

export default {
  name: "personal-report",
  components: {
    TableContainer,
    BasicBtn,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    dateSearch: null,
    headers: [
      { text: "Numero de empleado", align: "left", sortable: true, value: "no_empleado" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "", align: " d-none", sortable: true, value: "nombre_completo" },
      { text: "Departamento", align: "left", sortable: true, value: "departamento" },
      { text: "Puesto", align: "left", sortable: true, value: "puesto" },
      { text: "Coordinador de departamento", align: "left", sortable: true, value: "jefe_nombre" },
      { text: "Telefono", align: "left", sortable: true, value: "telefono" },
      { text: "Fecha de ingreso", align: "left", sortable: true, value: "fecha_ingreso" },
      { text: "Fecha de nacimiento", align: "left", sortable: true, value: "fecha_nacimiento" },
      { text: "Tipo de horario", align: "left", sortable: false, value: "tipo_horario" },
      { text: "Talla de playera", align: "left", sortable: false, value: "talla_playera" },
      { text: "Banco", align: "left", sortable: true, value: "banco" },
      { text: "Clabe", align: "left", sortable: true, value: "clabe" },
      { text: "Cuenta Bancaria", align: "left", sortable: true, value: "cuenta_bancaria" },
      { text: "Estado", align: "left", sortable: true, value: "estado" },
    ],
    items: [],
    currItems: [],
    itemsPerPage: 20,
    meses: [
      { text: 'Enero', value: 1 },
      { text: 'Febrero', value: 2 },
      { text: 'Marzo', value: 3 },
      { text: 'Abril', value: 4 },
      { text: 'Mayo', value: 5 },
      { text: 'Junio', value: 6 },
      { text: 'Julio', value: 7 },
      { text: 'Agosto', value: 8 },
      { text: 'Septiembre', value: 9 },
      { text: 'Octubre', value: 10 },
      { text: 'Noviembre', value: 11 },
      { text: 'Diciembre', value: 12 },
    ],
    forms: {
      filter: {
        mes_nacimiento: ''
      }
    },
    dialogs: {
      filter: false
    }
  }),
  computed: {
    exportItems() {
      let array = [];
      for (let data of this.items) {
        if (data.estado == 'ACTIVO') {
          array.push(data);
        }
      }
      if (this.itemsPerPage == -1) {
        return this.currItems
      }
      else {
        return array;
      }
    },
  },
  mounted() {
    this.refresh()
  },
  watch: {
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    }
  },
  methods: {
    refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.user + "/report" )
        .then((response) => {
          this.items = response.data.data;
          for (let i = 0; i < this.items.length; i++) {
            for (let key in this.items[i]) {
              if (key == 'fecha_ingreso' || key == 'fecha_nacimiento') {
                this.items[i][key] = this.dateFormat(this.items[i][key]);
              }
              else if(key == 'clabe'){
                this.items[i][key] = this.items[i][key] ? "'" + this.items[i][key].toString() + "'" : ''
              }
              else {
                this.items[i][key] = this.items[i][key] ? this.items[i][key] : ''
              }
            }
            this.items[i].nombre_completo = this.items[i].nombre + " " + this.items[i].apellido_paterno + " " + this.items[i].apellido_materno
            this.items[i].jefe_nombre_completo = this.items[i].jefe_nombre + " " + this.items[i].jefe_apellido_paterno + " " + this.items[i].jefe_apellido_materno
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Registros obtenidos con éxito";
        });
    },
    dateFormat(date) {
      return date ? moment(date).format("DD/MM/YYYY") : 'Desconocido';
    },
    cancel() {
      if (this.dialogs.filter) {
        this.forms.filter.mes_nacimiento = null
        this.dialogs.filter = false
        this.refresh()
      }
    },
    getFiltered(values) {
      this.currItems = values
    },
    applyFilters() {
      if (this.forms.filter.mes_nacimiento) {
        this.loading = true
        setTimeout(() => {
          let auxItems = []
          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].fecha_nacimiento) {
              const month = parseInt(this.items[i].fecha_nacimiento.split('/')[1])
              if (this.forms.filter.mes_nacimiento == month) {
                auxItems.push(this.items[i])
              }
            }
          }
          this.items = auxItems
          this.loading = false
          this.dialogs.filter = false
          this.alert.active = true
          this.alert.color = "success"
          this.alert.msg = "Filtro aplicado exitosamente"
        }, 300);
      }
      else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Debe seleccionar un mes para aplicar el filtro";
      }
    }
  },
};
</script>

<style scoped>
</style>
